import React from "react"
import { graphql, Link } from "gatsby"
import events from "../lib/events"
import cart from "../lib/cart"
import { modal } from "../lib/modal"
import Icon from "../components/icon"
import Layout from "../components/layout"
import Header from "../components/header"
import PageTitle from "../components/page-title"
import ProductList from "../components/product-list"
import ProductImage from "../components/product-image"
import { FreeShippingSlim } from "../components/free-shipping"
import { BlackLink } from "../components/black-buttons"
import InDollars from "../components/in-dollars"
import _sumBy from "lodash/sumBy"

const CartItem = (props) => (
	<li className='w-100 cf mb2 bb b--black-05 pv2'>
		<div className='w-100 w-80-ns fl relative'>
			<div className='fl w-20 w-10-m w-10-l'>
				<ProductImage product={ props.product } idx={ 1 } />
			</div>
			<div className='w-80 v-mid fl'>
				<p className='arvo fw4 f4 ma0 pa0 pl3 mb3 black-90'><Link className='blue no-underline dim' to={"/shop/" + props.product.fields.category_slug + props.product.fields.path}>{ props.product.fields.vanity_name }</Link></p>
				<p className='f6 ma0 pa0 pl3 mb2 black-50'>{ props.product.material}{ props.item.color && <span>, { props.item.color }</span> }</p>
				{ props.item.customization &&
					<p className='ma0 pa0 pl3 mb2 f6 black-50'>Customization: <span className='black-80'>{ props.item.customization }</span></p>
				}
				{ props.item.names &&
					<p className='ma0 pa0 pl3 mb2 f6 black-50'>Names: <span className='black-80'>{ props.item.names.join(', ') }</span></p>
				}
				{ props.item.range &&
					<p className='ma0 pa0 pl3 mb2 f6 black-50'>Numbers: <span className='black-80'>{ props.item.range[0] } &mdash; { props.item.range[1] }</span></p>
				}
				{ props.item.size &&
					<p className='ma0 pa0 pl3 mb2 f6 black-50'>Size: <span className='black-80'>{ props.item.size }</span></p>
				}
				{ props.item.first &&
					<p className='ma0 pa0 pl3 mb2 f6 black-50'>First Course: <span className='black-80'>{ props.item.first }</span></p>
				}
				{ props.item.second &&
					<p className='ma0 pa0 pl3 mb2 f6 black-50'>Second Course: <span className='black-80'>{ props.item.second }</span></p>
				}
				{ props.item.third &&
					<p className='ma0 pa0 pl3 mb2 f6 black-50'>Dessert: <span className='black-80'>{ props.item.third }</span></p>
				}
			</div>
			<div className='f4 absolute right-0 pt4 nr1 dn db-ns'>
				<Icon onClick={ props.onRemove } className='mt1 black-50 dim pointer db' icon="trash" style={{ width: 18, height: 18 }} />
			</div>
		</div>
		<div className='w-100 w-20-ns fl'>
			<div className='w-50 f4 tc-ns pt4 v-mid fl'>
				<span className='black-50 f5 pr2 dn-ns'>QTY</span>
				<input disabled={ props.disabled } type="text" pattern="[0-9]*" value={ props.item.quantity } onChange={ props.onChange } className={'bg-transparent bg-animate black-80 tc w-20 ' + (props.disabled ? ' bn' : ' ba b--black-10 hover-bg-black-05') } style={{ width: '3em' }} />
				<button className={'ma0 pa0 bg-transparent bb-only bb bw1 b--black-10 pointer dim black-50 f6 ml3 dn-ns ' } onClick={ props.onRemove }>Remove</button>
			</div>
			<div className='w-50 f4 tr tc-ns pt4 fl v-mid black-80 fadeinUp' key={ props.item.quantity }>
				<InDollars total={ props.item.price * props.item.quantity * 100 } />
			</div>
		</div>
	</li>
)

class CartPage extends React.Component {
	constructor(props) {
		super(props);
		events.subscribe('cartSize', 'cartPage', this);
	}
	componentWillUnmount() {
		events.unsubscribe('cartSize', 'cartPage');
	}
	renderCartItems() {
		const items = cart.mapped(this.props.data.products.edges.map(item => item.node));
		return items.map((o, idx) =>
			<CartItem disabled={ o.category === 'table_numbers' } key={idx} item={ o } product={ o.product } onChange={ this.updateQuantity(idx, o) } onRemove={ this.removeItem(idx, o) } />
		);
	}
	removeItem = (idx, item) => {
		const self = this;
		return function(event) {
			modal.show('QUANTITY_ZERO', {
				item: item,
				confirm: () => {
					modal.hide();
					cart.updateQuantity(idx, 0);
				}
			});
			return;
		}
	}
	updateQuantity = (idx, item) => {
		const self = this;
		return function(event) {
		  let quantity = parseInt(event.target.value);
		  if(isNaN(quantity)) {
			quantity = 1;
		  }
		  if(quantity === 0) {
			modal.show('QUANTITY_ZERO', {
				item: item,
				confirm: () => {
					modal.hide();
					cart.updateQuantity(idx, 0)
				}
			});
			return;
		  }
		  cart.updateQuantity(idx, quantity);
		}
	}
	renderCart() {
		const total = cart.sum(this.props.data.products.edges.map(item => item.node))
		return (
			<div className='cf'>
				<table className='w-100 dt--fixed'>
					<thead>
						<tr>
							<th className="w-100 w-10-ns bb b--black-05 pb1"></th>
							<th className="bb b--black-05 pb1"></th>
							<th className="w2 bb b--black-05 pb1"></th>
							<th className="w-10 fw4 i f5 bb b--black-05 pb2">Quantity</th>
							<th className="w-10 fw4 i f5 bb b--black-05 pb2">Price</th>
						</tr>
					</thead>
					<tbody className=''>
						{ this.renderCartItems() }
					</tbody>
				</table>
				<div className='cf'>
					<div className='w-100 w-50-ns fr tr pv4'>
						<div className='db dib-ns pr4-ns mb3 mb0-ns black-90'>
							<span className='fw4 i f5 pb1 pr3'>Subtotal: </span>
							<InDollars total={ total } />
						</div>
						<BlackLink to="/checkout" title="Checkout" />
					</div>
				</div>
			</div>
		)
	}
	renderCartList() {
		const total = cart.sum(this.props.data.products.edges.map(item => item.node))
		return (
			<div className='cf'>
				<div className='w-100 cf bb b--black-05 mb2 pb2 dn db-ns'>
					<div className='w-80 fl'>&nbsp;</div>
					<div className='w-10 fl tc'><p className="fw4 ma0 pa0 i f5">Quantity</p></div>
					<div className='w-10 fl tc'><p className="fw4 ma0 pa0 i f5">Price</p></div>
				</div>
				<ul className='list ma0 pa0 bt b--black-05 bn-ns'>
					{ this.renderCartItems() }
				</ul>
				<div className='cf'>
					<div className='w-100 w-50-ns fr tr pt3 pt4-l pb4'>
						<div className='db dib-ns pr4-ns mb4 mb0-ns black-90'>
							<span className='fw4 i f5 pb1 pr3'>Subtotal: </span>
							<InDollars total={ total } />
						</div>
						<BlackLink to="/checkout" title="Checkout" />
					</div>
				</div>
				<div className='tl tr-ns pt2'>
					{ this.renderShipping() }
					<p className='f7 pa0 ma0 mt2'><Link className='blue no-underline dim' to="/faq">*$10 for orders with backdrop signs.</Link></p>
				</div>
			</div>
		)
	}
	renderEmpty() {
		return (
			<p className='i black-90'>This cart's looking a little empty. Not sure how to fill it? <Link className='blue no-underline dim' to="/shop">Here are some ideas.</Link></p>
		)
	}
	renderShipping = () => {
		const remaining = 100 - (cart.sum() / 100);
		const mapped = cart.mapped(this.props.data.products.edges.map(item => item.node));
		const has_sign = mapped.find(prod => prod.category === 'signs');
		if(remaining > 0) {
			return (
				<FreeShippingSlim>
					Order ${ remaining } more and qualify for <span className='b'>free standard shipping*</span>.
				</FreeShippingSlim>
			)
		}
		return (
			<FreeShippingSlim>
				Good news! You qualify for <span className='b'>free standard shipping*</span>.
			</FreeShippingSlim>
		)
	}
	render() {
		const size = parseInt(cart.size());
		return (
			<Layout>
				<div className='ph3 ph4-ns pb3 pb4-ns pb5-l pt3 pt0-ns'>
					<PageTitle title="Cart"></PageTitle>
					{ size === 0 && this.renderEmpty() }
					{ size > 0 && this.renderCartList() }
				</div>
			</Layout>
		)
	}
}

export default CartPage;

export const query = graphql`
	query Cart {
		products: allAirtableProducts {
			totalCount
			edges {
				node {
					name
					category
					customizable
					material
					base_price
					premium_price
					fields {
						slug
						path
						category_slug
						vanity_name
					}
				}
			}
		}
  }
`